import React from "react"
import Seo from '../components/seo';
import Layout from '../containers/layout/layout'
import Banner from '../containers/company/banner'
import About from '../containers/company/about'
import Testimonial from '../containers/company/testimonial'

const CompanyPage = (props) => (
	<Layout lang={props.pageContext.langKey}>
		<Seo title="معلومات عن الجميعية" url="/about" lang={props.pageContext.langKey} />
		<Banner />
		<About lang={props.pageContext.langKey} />
		{/* <Team /> */}
		{/* <JoinOurTeam lang={props.pageContext.langKey} /> */}
		<Testimonial />
	</Layout>
)

export default CompanyPage;
