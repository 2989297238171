import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import SlickSlider from '../../../components/shared/slick'
import Testimonial from '../../../components/testimonial'
import { TestimonialWrap } from './testimonial.stc'

const TestimonialSection = ({ section, options }) => {
	const testimonialData = useStaticQuery(graphql`
        query AboutTestimonialQuery {
            allTestimonialJson {
                edges {
                    node{
                        id
                        i18n {
                        	ar {
								author_name
								author_role
                        		review
                        	}
                        	en {
								author_name
								author_role
                        		review
                        	}
						}
						author_image {
							childImageSharp {
							  	gatsbyImageData(width: 88, height: 88, layout: FIXED, quality: 100, placeholder: TRACED_SVG)
							}
						}
                    }
                }
            }
        }
    `);
	const testimonials = testimonialData.allTestimonialJson.edges;
	return (
		<TestimonialWrap {...section}>
			<div className="row align-items-center">
				<div className="col-6">
					<SlickSlider options={options}>
						{testimonials.map(data => (
							<div className="item" key={data.node.id}>
								<Testimonial
									author_name={data.node.author_name}
									author_role={data.node.author_role}
									company={data.node.company}
									country={data.node.country}
									author_image={data.node.author_image}
									review={data.node.review}
								/>
							</div>
						))}
					</SlickSlider>
				</div>
			</div>
		</TestimonialWrap>
	)
}

TestimonialSection.propTypes = {
	section: PropTypes.object
}

TestimonialSection.defaultProps = {
	section: {
		backgroundColor: '#f8f8f8'
	},
	options: {
		slidesToShow: 1,
		dots: true
	}
}

export default TestimonialSection
