import React from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import { AboutSectionWrap, SectionTitle, AboutContent, ProjectTypeList } from './about.stc'

const About = ({ HeadingStyle, TextStyle, ProjectTypeHeading, ProjectTypeText, lang }) => {
	const aboutQueryData = useStaticQuery(graphql`
        query AboutDataQuery {
            aboutdataJson(section: {eq: "about-intro-content"}) {
                id
				i18n {
					en {
						title
						desc
						content
						contentHtml
					}
					ar {
						title
						desc
						content
						contentHtml
					}
				}
                featured_work {
                    id
					i18n {
						en {
							title
							subtitle
						}
						ar {
							title
							subtitle
						}
					}
                }
            }
			translationJson {
				aboutUs {
				  ourGoals { ar en }
				}
			}
        }      
    `);
	const aboutdataJson = { ...aboutQueryData.aboutdataJson, ...aboutQueryData.aboutdataJson.i18n[lang] };
	const { title, desc, content, contentHtml } = aboutdataJson;
	const featured_work = aboutdataJson.featured_work.map(work => {
		return { ...work, ...work.i18n[lang] }
	});
	const translations = aboutQueryData.translationJson.aboutUs;
	const titles = {};
    Object.keys(translations).forEach(key => {
		titles[key] = translations[key][lang];
	});
	const delay = [200];
	return (
		<AboutSectionWrap>
			<div className="row">
				{(title || desc) && (
					<div className="col-1 offset-1">
						<SectionTitle>
							{title && <Heading {...HeadingStyle}>{title}</Heading>}
							{desc && <Text {...TextStyle}>{desc}</Text>}
						</SectionTitle>
					</div>
				)}
				{content && (
					<div className="col-2 offset-1">
						<AboutContent>
							<Text>{contentHtml}</Text>
						</AboutContent>
					</div>
				)}
			</div>
			{featured_work && (
				<div className="row section-pt-md">
					<div className="col-4 offset-1">
						<SectionTitle>
							<Heading {...HeadingStyle}>{titles.ourGoals}</Heading>
						</SectionTitle>
						<div className="content row">
							{featured_work.map((work, i) => {
								delay.push(delay[i] + 200)
								return (
									<div
										key={work.id}
										className="col-1 project-type wow fadeInLeft"
										data-wow-delay={`${delay}ms`}
										data-wow-duration="1000ms">
										{work.title && <Heading {...ProjectTypeHeading}>{work.title}</Heading>}
										{work.subtitle && (
											<ProjectTypeList>
												<Text {...ProjectTypeText}>{work.subtitle}</Text>
											</ProjectTypeList>
										)}
									</div>
								)
							})}
						</div>
					</div>
				</div>
			)}
		</AboutSectionWrap>
	)
}

About.propTypes = {
	HeadingStyle: PropTypes.object,
	TextStyle: PropTypes.object
}

About.defaultProps = {
	HeadingStyle: {
		fontSize: '40px',
		texttransform: 'capitalize',
		lineHeight: '55px',
		color: 'primary',
		fontweight: 600,
		responsive: {
			medium: {
				fontSize: '24px',
				lineHeight: 'initial'
			},
			small: {
				fontSize: '25px',
				lineHeight: '35px'
			}
		}
	},
	TextStyle: {
		lineHeight: '26px'
	},
	ProjectTypeHeading: {
		as: 'h6',
		color: 'primary',
		fontSize: '14px',
		fontweight: 700,
		mb: '12px'
	},
	ProjectTypeText: {
		m: 0,
		paddingRight: '10px',
		fontSize: '13px',
		color: '#000000'
	}
}

export default About;